<ng-container *ngIf="codeProtectionService.isInitialized()">
  <ng-container *ngIf="codeProtectionService.isAccessGranted(); else codeEntry">
    <div>
      <app-header (toggle)="drawer.toggle()" [openedMenu]="drawer.opened"></app-header>
      <mat-drawer-container class="container" hasBackdrop="false">
        <mat-drawer (click)="environment.theme == 'classic' ? closeDrawerOnMobile() : null" fixedInViewport="true" [attr.role]="isMobile ? 'dialog' : 'navigation'"
        [mode]="isMobile ? 'over' : 'side'" 
        [opened]="opened" 
        [ngClass]="environment.theme == 'trdevents' ? 'full-screen' : ''" #drawer>
          <app-menu [loading]="loading" [user$]="user$" [showRsvp]="environment.ui.showOnForm.rsvp" [drawer]="isMobile ? drawer : null"></app-menu>
        </mat-drawer>
        <mat-drawer-content [ngClass]="{ 'no-bottom-margin': environment.theme == 'trdevents'}">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
      <app-footer></app-footer>
    </div>
  </ng-container>

  <ng-template #codeEntry>
    <app-enter-code></app-enter-code>
  </ng-template>
</ng-container>