<div class="classic" *ngIf="environment.theme == 'classic'">
  <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="newsletterForm"
    (submit)="addSubscriber(formDirective)" #formDirective="ngForm">
    <div fxFlex="60px" fxFlex.xs="grow">
      <h4 translate *ngIf="!environment.ui.titleNewsletter">Subscribe to our newsletter:</h4>
      <h4 *ngIf="environment.ui.titleNewsletter && environment.ui.titleNewsletter.nb && translate.currentLang == 'nb'">
        {{environment.ui.titleNewsletter.nb}}:</h4>
      <h4 *ngIf="environment.ui.titleNewsletter && environment.ui.titleNewsletter.en && translate.currentLang != 'nb'">
        {{environment.ui.titleNewsletter.en}}:</h4>
    </div>
    <div fxFlex.xs="grow" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
      <mat-form-field hideRequiredMarker="true" floatLabel="never" fxFlex.xs="auto" fxFlex="300px">
        <input trim="blur" type="email" [placeholder]="'Enter your email' | translate" matInput formControlName="email">
        <mat-label>{{'Enter your email' | translate}}<mat-icon>near_me</mat-icon></mat-label>
        <mat-error class="error" fxFlex="100" *ngIf="newsletterForm.hasError('email',['email'])" translate>
          You must introduce a valid email address
        </mat-error>
        <mat-error class="error" fxFlex="100" *ngIf="newsletterForm.hasError('required',['email'])" translate>
          You must introduce a valid email address
        </mat-error>
      </mat-form-field>
      <button class="subscribe-button" fxFlex="100px" fxFlex.xs="40px" [disabled]="newsletterForm.invalid || sending"
        mat-raised-button color="primary" type="submit"><span translate>Subscribe!</span></button>
      <div fxFlex="100px" fxFlex.xs="40px" *ngIf="sending">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
    </div>
    <div fxFlex="20px" class="error" *ngIf="result && !result.success && result.didYouMean">
      {{'Did you mean' | translate }} <strong>{{result.didYouMean}}</strong>?
    </div>
    <div fxFlex="20px" class="agreement"><span translate>By subscribing to our newsletter, you are agreeing to our
      </span> <a class="blue" routerLink="/privacy-policy"><span translate>privacy policy</span></a></div>
  </form>
</div>

<div class="trdevents" *ngIf="environment.theme == 'trdevents'">
  <form [formGroup]="newsletterForm" (ngSubmit)="addSubscriber(formDirective)" #formDirective="ngForm">
    <div class="title">{{ translate.currentLang == 'nb' ? environment?.ui?.titleNewsletter?.nb :
      environment?.ui?.titleNewsletter?.en}}</div>
    <div>
      <input trim="blur" type="email" [placeholder]="'Email' | translate" formControlName="email">
      <div class="error" *ngIf="newsletterForm.invalid 
      && (newsletterForm.dirty || newsletterForm.touched) 
      && (newsletterForm.hasError('email',['email']) || newsletterForm.hasError('required',['email']))" translate>
        You must introduce a valid email address</div>
    </div>
    <div>
      <input id="check" type="checkbox" class="styled-checkbox" formControlName="agree">
      <label for="check" class="checkbox-label">
        {{'I accept the' | translate}} <a routerLink="/privacy-policy">{{'terms of use' | translate}}</a>
      </label>
    </div>
    <div class="error" *ngIf="newsletterForm.invalid 
      && (newsletterForm.dirty || newsletterForm.touched) 
      && (newsletterForm.hasError('requiredTrue',['agree']))" translate>
      You must accept the terms of use</div>
    <button type="submit" [disabled]="newsletterForm.invalid || sending">
      {{'Subscribe' | translate}}
    </button>
    <div *ngIf="sending">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    <div class="error" *ngIf="result?.didYouMean && result?.success == false">
      {{'Did you mean' | translate }} <strong>{{result.didYouMean}}</strong>?
    </div>
  </form>
</div>