import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../models/event';
import { UtilsService } from '../services/utils.service';
import moment from 'moment';

@Component({
  selector: 'app-booking-guest',
  templateUrl: './booking-guest.component.html',
  styleUrls: ['./booking-guest.component.scss']
})
export class BookingGuestComponent implements OnInit {

  @Input()
  event: Event;

  bookingAvailable: boolean = false;

  constructor(private utilsService: UtilsService) { }

  ngOnInit(): void {
    if (!this.event?.eventCancelled) {
      if (this.event?.ticketsFromDate?.seconds) {
        const openingDate = new Date(this.event?.ticketsFromDate?.seconds * 1000);
        this.bookingAvailable = openingDate.getTime() < (new Date()).getTime();
      } else {
        this.bookingAvailable = true;
      }
    }
  }
  printDate(dateRaw: any): string {
    const date = moment(dateRaw).isValid() ? moment(dateRaw) : moment(dateRaw.seconds * 1000);
    return date.format(this.utilsService.getDateFormat());
  }
  printTime(dateRaw: any): string {
    const date = moment(dateRaw).isValid() ? moment(dateRaw) : moment(dateRaw.seconds * 1000);
    return date.format(this.utilsService.getTimeFormat());
  }
}
