import { AuthService } from './../services/auth.service';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

  environment: any;

  constructor(private _authService: AuthService, private _router: Router,
    @Inject(PLATFORM_ID) private platformId: any) {
    this.environment = environment;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this._authService.getCurrentUser().pipe(
        map((user: any) => {
          if (user) {
            return true;
          } else {
            if (this.environment.theme === 'trdevents') {
              // Navigate to the organizers outlet login
              this._router.navigate(['/organizers', { outlets: { organizers: ['login'] } }], {
                queryParams: {
                  redirectTo: state.url
                }
              });
            } else {
              // Regular login route
              this._router.navigate(['/login'], {
                queryParams: {
                  redirectTo: state.url
                }
              });
            }
            return false;
          }
        })
      );
    }
    
    // Server-side rendering case
    this._router.navigate(['/login'], {
      queryParams: {
        redirectTo: state.url
      }
    });
    return false;
  }
}