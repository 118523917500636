<div class="wrapper" [ngClass]="environment.theme == 'trdevents' ? 'trdevents' : 'classic'">
  <div class="mat-body privacy-policy mat-elevation-z1 page">
    <div *ngIf="translate.currentLang == 'en'">
      <h1 class="mat-h1">Privacy Policy</h1>

      <p>Effective date: November 12, 2018</p>

      <p>Margareto Software AS ("us", "we", or "our") operates the {{environment.siteURL}} website (the "Service").</p>

      <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

      <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>


      <h2 class="mat-h2">Information Collection And Use</h2>

      <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

      <h3 class="mat-h3">Types of Data Collected</h3>

      <h4 class="mat-h4">Personal Data</h4>

      <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">Cookies and Usage Data</li>
      </ul>

      <h4 class="mat-h4" *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">Usage Data</h4>

      <p *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of
        our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

      <h4 class="mat-h4" *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">Tracking & Cookies Data</h4>
      <p *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
      <p *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to
        collect and track information and to improve and analyze our Service.</p>
      <p *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook" >You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
      <p *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">Examples of Cookies we use:</p>
      <ul *ngIf="environment.cookies.analytics || environment.cookies.login.google || environment.cookies.login.facebook">
        <li><strong>Session Cookies.</strong> We use Session Cookies to operate our Service.</li>
        <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
        <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
      </ul>

      <h2 class="mat-h2">Use of Data</h2>

      <p>Margareto Software AS uses the collected data for various purposes:</p>
      <ul>
        <li>To provide and maintain the Service</li>
        <li>To notify you about changes to our Service</li>
        <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
        <li>To provide customer care and support</li>
        <li>To provide analysis or valuable information so that we can improve the Service</li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>

      <h2 class="mat-h2">Export and deletion of your Data</h2>

      <p>You can always export or delete your stored data and account at <a [href]="environment.siteURL + '/account'" target="_blank">{{environment.siteURL}}/account</a></p>

      <h2 class="mat-h2">Transfer Of Data</h2>
      <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from
        your jurisdiction.</p>
      <p>If you are located outside Norway and choose to provide information to us, please note that we transfer the data, including Personal Data, to Norway and process it there.</p>
      <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
      <p>Margareto Software AS will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless
        there are adequate controls in place including the security of your data and other personal information.</p>

      <h2 class="mat-h2">Disclosure Of Data</h2>

      <h3 class="mat-h3">Legal Requirements</h3>
      <p>Margareto Software AS may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Margareto Software AS</li>
        <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
        <li>To protect the personal safety of users of the Service or the public</li>
        <li>To protect against legal liability</li>
      </ul>

      <h2 class="mat-h2">Security Of Data</h2>
      <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data,
        we cannot guarantee its absolute security.</p>

      <h2 class="mat-h2">Service Providers</h2>
      <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
      <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

      <h3 class="mat-h3" *ngIf="environment.cookies.analytics">Analytics</h3>
      <p *ngIf="environment.cookies.analytics">We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
      <ul *ngIf="environment.cookies.analytics">
        <li>
          <p><strong>Google Analytics</strong></p>
          <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google
            may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
          <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js)
            from sharing information with Google Analytics about visits activity.</p>
          <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></p>
        </li>
      </ul>


      <h2 class="mat-h2">Links To Other Sites</h2>
      <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>


      <h2 class="mat-h2">Children's Privacy</h2>
      <p>Our Service does not address anyone under the age of 18 ("Children").</p>
      <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware
        that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>


      <h2 class="mat-h2">Changes To This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


      <h2 class="mat-h2">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us:</p>
      <ul>
        <li *ngIf="environment.contact.email">By email: {{environment.contact.email}}</li>
        <li *ngIf="environment.contact.phone">By phone: {{environment.contact.phone}}</li>
      </ul>
    </div>
    <div *ngIf="translate.currentLang == 'nb'">
      <h1 class="mat-h1">Personvernregler</h1>
      <p>Effektiv dato: 12. november 2018</p>

      <p>Margareto Software AS ("oss", "vi" eller "våre") driver nettstedet {{environment.siteURL}} ("Tjenesten").</p>

      <p>Denne siden informerer deg om våre retningslinjer for innsamling, bruk og avsløring av personopplysninger når du bruker vår tjeneste og valgene du har knyttet til disse dataene.</p>

      <p>Vi bruker dataene dine til å tilby og forbedre tjenesten. Ved å bruke Tjenesten, godtar du samlingen og bruken av informasjon i samsvar med denne policyen.</p>

      <h2 class="mat-h2">Informasjonsinnsamling og bruk</h2>
      <p>Vi samler flere forskjellige typer informasjon til ulike formål for å tilby og forbedre vår Service til deg.</p>

      <h3 class="mat-h3">Typer data samlet</h3>
      <h4 class="mat-h4">Personlig informasjon</h4>
      <p>Når du bruker vår tjeneste, kan vi be deg om å gi oss visse personopplysninger som kan brukes til å kontakte eller identifisere deg ("Personlige data"). Personlig identifiserbar informasjon kan inneholde, men er ikke begrenset til:<p>
          <ul>
            <li>Epostadresse</li>
            <li>Fornavn og etternavn</li>
            <li>Telefonnummer</li>
            <li>Informasjonskapsler og bruksdatav</li>
          </ul>

          <h4 *ngIf="environment.cookies.analytics" class="mat-h4">Brukerdata</h4>
          <p *ngIf="environment.cookies.analytics">Vi kan også samle inn informasjon om hvordan Tjenesten er tilgjengelig og brukt ("Brukdata"). Disse bruksdataene kan inneholde informasjon som datamaskinens Internet Protocol-adresse (f.eks. IP-adresse), nettlesertype, nettleserversjon,
            sidene i vår tjeneste som du besøker, klokkeslett og dato for besøket, tid brukt på disse sidene, unikt enhetsidentifikatorer og andre diagnostiske data.</p>

          <h4 *ngIf="environment.cookies.analytics" class="mat-h4">Sporings- og informasjonskapsler</h4>
          <p *ngIf="environment.cookies.analytics">Vi bruker informasjonskapsler og lignende sporingsteknologier for å spore aktiviteten i vår tjeneste og holde visse opplysninger.</p>

          <p *ngIf="environment.cookies.analytics">Informasjonskapsler er filer med liten mengde data som kan inneholde en anonym unik identifikator. Informasjonskapsler sendes til nettleseren din fra et nettsted og lagres på enheten. Sporteknologier som også brukes, er beacons, tags og
            skript for å samle inn og spore informasjon og forbedre og analysere vår Service.</p>

          <p *ngIf="environment.cookies.analytics">Du kan instruere nettleseren din til å nekte alle informasjonskapsler eller angi når en informasjonskapsel sendes. Men hvis du ikke godtar informasjonskapsler, kan du kanskje ikke bruke noen deler av tjenesten vår.</p>

          <p *ngIf="environment.cookies.analytics">Eksempler på cookies vi bruker:</p>
          <ul *ngIf="environment.cookies.analytics">
            <li>Session Cookies. Vi bruker Session Cookies til å betjene vår Service.</li>
            <li>Preference Cookies. Vi bruker Preference Cookies for å huske dine preferanser og ulike innstillinger.</li>
            <li>Sikkerhetskaker. Vi bruker Security Cookies for sikkerhetsformål.</li>
          </ul>

          <h2 class="mat-h2">Bruk av data</h2>
          <p>Margareto Software AS bruker de innsamlede dataene til forskjellige formål:</p>
          <ul>
            <li>Å gi og vedlikeholde Tjenesten</li>
            <li>Å varsle deg om endringer i vår Service</li>
            <li>Å tillate deg å delta i interaktive funksjoner i vår tjeneste når du velger å gjøre det</li>
            <li>Å gi kundeservice og støtte</li>
            <li>Å gi analyse eller verdifull informasjon slik at vi kan forbedre tjenesten</li>
            <li>For å overvåke bruken av Tjenesten</li>
            <li>Å oppdage, forhindre og adressere tekniske problemer</li>
          </ul>

          <h2 class="mat-h2">Eksport og sletting av dataene dine</h2>
          <p>Du kan alltid eksportere eller slette lagrede data og kontoer på <a [href]="environment.siteURL + '/account'" target="_blank">{{environment.siteURL}}/account</a></p>

          <h2 class="mat-h2">Overføring av data</h2>
          <p>Informasjonen din, inkludert Personopplysninger, kan overføres til - og vedlikeholdes på - datamaskiner som ligger utenfor din stat, provins, land eller annen myndighets jurisdiksjon der databeskyttelseslovene kan være forskjellige enn
            de som er fra din jurisdiksjon.</p>

          <p>Hvis du befinner deg utenfor Norge og velger å gi informasjon til oss, vær oppmerksom på at vi overfører dataene, inkludert Personopplysninger, til Norge og behandler det der.</p>

          <p>Ditt samtykke til denne personvernreglene, etterfulgt av din innlevering av slik informasjon, representerer din avtale for denne overføringen.</p>

          <p>Margareto Software AS vil ta alle nødvendige skritt for å sikre at dataene dine blir behandlet sikkert og i samsvar med denne personvernreglene, og ingen overføring av dine personlige data vil finne sted til en organisasjon eller et land med
            mindre det er tilstrekkelig kontroll på plass, inkludert sikkerheten til dine data og annen personlig informasjon.</p>

          <h2 class="mat-h2">Opplysning av data</h2>
          <h3 class="mat-h3">Lovlige krav</h3>
          <p>Margareto Software AS kan avsløre dine personlige data i god tro på at slik handling er nødvendig for å:</p>
          <ul>
            <li>Å overholde en lovlig forpliktelse</li>
            <li>For å beskytte og forsvare rettighetene eller eiendommen til Margareto Software AS</li>
            <li>For å forhindre eller undersøke mulig feil i forbindelse med Tjenesten</li>
            <li>For å beskytte den personlige sikkerhet for brukere av Tjenesten eller offentligheten</li>
            <li>For å beskytte mot lovlig ansvar</li>
          </ul>

          <h2 class="mat-h2">Sikkerhet av data</h2>
          <p>Sikkerheten til dataene dine er viktig for oss, men husk at ingen overføringsmetode over Internett, eller elektronisk lagringsmetode, er 100% sikker. Mens vi streber etter å bruke kommersielt akseptable metoder for å beskytte dine
            personlige data, kan vi ikke garantere den absolutte sikkerheten.</p>

          <h2 class="mat-h2">Tjenestetilbydere</h2>
          <p>Vi kan ansette tredjepartsfirmaer og enkeltpersoner for å lette vår Service ("Service Providers"), for å levere Tjenesten på våre vegne, å utføre Service-relaterte tjenester eller å hjelpe oss med å analysere hvordan vår Service brukes.</p>

          <p>Disse tredjepartene har bare tilgang til dine personopplysninger for å utføre disse oppgavene på våre vegne og er forpliktet til ikke å offentliggjøre eller bruke det til andre formål.</p>

          <h3 *ngIf="environment.cookies.analytics" class="mat-h3">Analytics</h3>
          <p *ngIf="environment.cookies.analytics">Vi kan bruke tredjeparts tjenesteleverandører til å overvåke og analysere bruken av vår tjeneste.</p>
          <ul *ngIf="environment.cookies.analytics">
            <li>
              <h4 class="mat-h4">Google Analytics</h4>
              <p>Google Analytics er en webanalysetjeneste som tilbys av Google som sporer og rapporterer nettstedstrafikk. Google bruker dataene som samles inn for å spore og overvåke bruken av tjenesten vår. Disse dataene deles med andre
                Google-tjenester. Google kan bruke de innsamlede dataene til å kontekstualisere og tilpasse annonsene i sitt eget annonseringsnettverk.</p>
              <p>Du kan ekskludere for at du har gjort aktiviteten din til Tjenesten tilgjengelig for Google Analytics, ved å installere tilleggsprogrammet for Google Analytics-tilkobling. Add-onen forhindrer Google Analytics JavaScript (ga.js,
                analytics.js og dc.js) fra å dele informasjon med Google Analytics om besøksaktivitet.</p>
              <p>Hvis du vil ha mer informasjon om personvernpraksis fra Google, kan du gå til websiden for personvern og vilkår for Google: https://policies.google.com/privacy?hl=no</p>
            </li>
          </ul>

          <h2 class="mat-h2">Lenker til andre nettsteder</h2>
          <p>Vår tjeneste kan inneholde lenker til andre nettsteder som ikke drives av oss. Hvis du klikker på en tredjeparts lenke, blir du sendt til den tredje partens nettsted. Vi anbefaler på det sterkeste at du gjennomgår personvernreglene for
            hvert nettsted du besøker.</p>

          <p>Vi har ingen kontroll over og påtar oss intet ansvar for innholdet, retningslinjer for personvern eller praksis for tredjeparts nettsteder eller tjenester.</p>

          <h2 class="mat-h2">Barns personvern</h2>
          <p>Vår tjeneste tar ikke opp noen under 18 år ("barn").</p>

          <p>Vi samler ikke bevisst personlig identifiserbar informasjon fra alle under 18 år. Hvis du er foreldre eller foresatte, og du er klar over at barna dine har gitt oss personopplysninger, vennligst kontakt oss. Hvis vi blir klar over at vi
            har samlet personopplysninger fra barn uten verifisering av foreldres samtykke, tar vi skritt for å fjerne denne informasjonen fra våre servere.</p>

          <h2 class="mat-h2">Endringer i denne personvernpolitikken</h2>
          <p>Vi kan oppdatere våre personvernregler fra tid til annen. Vi vil varsle deg om eventuelle endringer ved å legge inn den nye personvernreglene på denne siden.</p>

          <p>Vi vil gi deg beskjed via e-post og / eller en fremtredende varsel på vår tjeneste, før endringen blir effektiv og oppdater "effektiv dato" øverst på denne personvernpolitikken.</p>

          <p>Du anbefaler at du gjennomgår denne personvernreglene regelmessig for eventuelle endringer. Endringer i denne personvernreglene er effektive når de blir lagt ut på denne siden.</p>

          <h2 class="mat-h2">Kontakt oss</h2>
          <p>Hvis du har spørsmål om denne personvernreglene, vennligst kontakt oss:</p>
          <ul>
            <li *ngIf="environment.contact.email">Ved e-post: {{environment.contact.email}}</li>
            <li *ngIf="environment.contact.phone">Ved telefon: {{environment.contact.phone}}</li>          
          </ul>
    </div>
  </div>
</div>
