<div class="code-container">
  <div class="code-box mat-elevation-z2">
    <h1>{{ 'Welcome' | translate }}</h1>
    <p>{{ 'Please enter the access code to continue' | translate }}</p>
    
    <mat-form-field appearance="outline" [class.shake]="error">
      <input matInput 
             [formControl]="codeControl" 
             type="text" 
             (keyup.enter)="checkCode()"
             [placeholder]="'Enter code' | translate"
             autocomplete="off">
    </mat-form-field>

    <div class="error-message" [class.show]="error">
      <mat-icon>error_outline</mat-icon>
      <span>{{ 'Invalid code' | translate }}</span>
      <span class="attempt-counter" *ngIf="attempts > 1">
        {{ attempts }}
      </span>
    </div>

    <button mat-raised-button 
            color="primary" 
            [disabled]="!codeControl.valid"
            (click)="checkCode()">
      {{ 'Continue' | translate }}
    </button>
  </div>
</div>
