import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Event } from '../models/event';
import { BookingService } from '../services/booking.service';
import { Booking } from '../models/booking';
import { environment } from '../../environments/environment';
import { LocalStorageService } from 'ngx-localstorage';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit {

  //Booking number form
  bookingNumberForm: FormGroup = new FormGroup({
    bookingNumber: new FormControl(),
    phoneNumber: new FormControl(null, {
      validators: [Validators.pattern(/^\d{8}$/i)],
      updateOn: 'blur'
    }),
    phoneReminder: new FormControl(null, {
      validators: [Validators.pattern(/^\d{8}$/i)],
      updateOn: 'blur'
    })
  });


  //Helpers
  loading: boolean = false;
  bookingsNotFound: boolean = false;
  reminderResultCode: string;
  showPhoneReminder: boolean = false;
  showCancellationWarning: boolean = false;
  environment: any;

  //Bookings
  bookings: Booking[];

  // Auth state
  isLoggedIn: boolean = false;

  constructor(
    private bookingService: BookingService,
    private storageService: LocalStorageService
  ) {
    this.environment = environment;
    // Add value transformation for phone fields
    ['phoneNumber', 'phoneReminder'].forEach(field => {
      this.bookingNumberForm.get(field)?.valueChanges.subscribe(value => {
        if (value) {
          const cleanValue = value.replace(/\s+/g, '');
          this.bookingNumberForm.get(field)?.setValue(cleanValue, { emitEvent: false });
        }
      });
    });
    this.checkStoredCredentials();
  }

  ngOnInit(): void {
  //  this.showCancellationWarning = (((this.booking?.startDate?.seconds - (this.event?.cancellationPeriod * 24 * 60 * 60)) * 1000) < (new Date()).getTime()) && this.environment?.rsvpOptions?.defaultSMS?.supportPhone;
  }

  private checkStoredCredentials(): void {
    const storedPhone = this.storageService.get('storedPhone', 'hvaskjerkalender');
    const storedBooking = this.storageService.get('storedBooking', 'hvaskjerkalender');
    
    if (storedPhone && storedBooking) {
      this.bookingNumberForm.patchValue({
        phoneNumber: storedPhone,
        bookingNumber: storedBooking
      });
      this.getTickets();
    }
  }

  //Get the tickets from the phone and the booking number
  getTickets(): void {
    if (this.bookingNumberForm.get('bookingNumber').valid && this.bookingNumberForm.get('phoneNumber').valid) {
      this.loading = true;
      const phone = this.bookingNumberForm.get("phoneNumber").value;
      const booking = this.bookingNumberForm.get("bookingNumber").value;
      
      const sub = this.bookingService.getMyTickets(phone, booking).subscribe(
        (bookings: Booking[] | null) => {
          this.loading = false;
          if (bookings) {
            this.bookings = bookings;
            this.bookingsNotFound = false;
            this.isLoggedIn = true;
            // Store credentials on successful login
            this.storageService.set('storedPhone', phone, 'hvaskjerkalender');
            this.storageService.set('storedBooking', booking, 'hvaskjerkalender');
          } else {
            this.bookingsNotFound = true;
            this.isLoggedIn = false;
          }
        }
      )
    }
  }

  //Resend to the user the booking number of the tickets for this events
  resendBookingNumber() {
    if (this.bookingNumberForm.get('phoneReminder').valid) {
      this.loading = true;
      const phone_number = this.bookingNumberForm.controls.phoneReminder.value;
      const sub = this.bookingService.resendBookingNumber(phone_number).subscribe(
        (result: any) => {
          sub?.unsubscribe();
          this.reminderResultCode = result.resultCode;
          if (result.resultCode == 'sms-sent') {
            this.showPhoneReminder = false;
          }
          this.loading = false;
        }
      );
    }
  }

  logout(): void {
    this.isLoggedIn = false;
    this.bookings = null;
    this.bookingNumberForm.reset();
    this.storageService.remove('storedPhone', 'hvaskjerkalender');
    this.storageService.remove('storedBooking', 'hvaskjerkalender');
  }
}
