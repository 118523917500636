import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Booking, Ticket } from '../models/booking';
import { formatDate } from '@angular/common';
import { BookingStatusService } from '../services/booking-status.service';
import { TicketTypeService } from '../services/ticket-type.service';
import { BookingService } from '../services/booking.service';
import { EventService } from '../services/event.service';
import { Event } from '../models/event';
import { UtilsService } from '../services/utils.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-booking-guest-editor',
  templateUrl: './booking-guest-editor.component.html',
  styleUrls: ['./booking-guest-editor.component.scss']
})
export class BookingGuestEditorComponent implements OnInit {

  @Input()
  bookings: Booking[];
  selectedBooking: Booking | null = null;
  maxCancellationDate: string;
  showPastBookings = false;
  showPastEvents: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private bookingService: BookingService,
    private eventService: EventService,
    private bookingStatusService: BookingStatusService,
    private ticketTypeService: TicketTypeService) { }

  ngOnInit(): void {
    if (this.bookings?.length) {
      this.selectBooking(this.bookings[0]);
    }
  }

  get filteredBookings(): Booking[] {
    const now = new Date().getTime() / 1000; // Convert to seconds
    return this.bookings?.filter(booking => {
      const isUpcoming = booking.startDate?.seconds > now;
      return this.showPastBookings ? !isUpcoming : isUpcoming;
    })
    .sort((a, b) => {
      // For upcoming: earliest first (ascending)
      // For past: latest first (descending)
      return this.showPastBookings 
        ? (b.startDate?.seconds || 0) - (a.startDate?.seconds || 0)
        : (a.startDate?.seconds || 0) - (b.startDate?.seconds || 0);
    }) || [];
  }

  togglePastBookings(): void {
    this.showPastBookings = !this.showPastBookings;
    this.selectedBooking = null;
  }

  selectBooking(booking: Booking): void {
    this.selectedBooking = this.selectedBooking?.id === booking.id ? null : booking;
    if (this.selectedBooking) {
      this.setupDeadlineForCancellation();
      this.listenOnBookingChanges();
    }
  }

  listenOnBookingChanges() {
    if (!this.selectedBooking) return;
    this.bookingService.getBooking(this.selectedBooking.id)
      .then((updatedBooking: Booking) => {
        this.selectedBooking = updatedBooking;
        // Update the booking in the list as well
        const index = this.bookings.findIndex(b => b.id === updatedBooking.id);
        if (index !== -1) {
          this.bookings[index] = updatedBooking;
        }
      });
  }

  setupDeadlineForCancellation() {
    if (!this.selectedBooking) return;
    const sub = this.eventService.getEventFromFirestore(this.selectedBooking.eventId, (event: Event) => {
      sub();
      const period: number = event.cancellationPeriod;
      this.maxCancellationDate = formatDate(new Date((this.selectedBooking?.startDate?.seconds - event.cancellationPeriod * 24 * 60 * 60) * 1000), "short", 'nb-NO');
    });
  }

  getDateTime(seconds: number): string {
    return formatDate(new Date(seconds * 1000), "short", 'nb-NO');
  }

  getStatus(status: string): string {
    return this.bookingStatusService.getBookingStatusName(status);
  }

  getTicketType(ticket: Ticket): string {
    return this.ticketTypeService.getTicketTypeName(ticket);
  }

  trim(myStr?: string, numChats?: number): string {
    return this.utilsService.trimWithMax(myStr || '', numChats || 70);
  }

}
