import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Booking, Ticket } from '../models/booking';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { BookingService } from '../services/booking.service';
import { EventService } from '../services/event.service';
import { Event } from '../models/event';

@Component({
  selector: 'app-booking-edition',
  templateUrl: './booking-edition.component.html',
  styleUrls: ['./booking-edition.component.scss']
})
export class BookingEditionComponent implements OnInit {

  @Input()
  booking: Booking;

  @Input()
  bookings: Booking[];

  @Input()
  isAdmin: boolean = false;

  @Output()
  onExecutionDone: EventEmitter<boolean> = new EventEmitter();

  action: string;
  authorEmail: string;
  //SMS, first content param, second content param
  validComponents: boolean[] = [];

  //Send or not SMS
  sendSMS: boolean = false;

  //SMS content
  smsContent: string = null;

  //Tickets
  tickets: Ticket[];

  //Customer name
  customerName: string;

  //Customer address
  customerAddress: string;

  //Customer phone
  customerPhone: string;

  //Customer comments
  customerComments: string;

  //Booking status
  bookingStatus: string;

  //Booking venue
  bookingVenue: string;

  //Booking start date
  bookingStartDate: any;

  //HELPERS
  beforeCancellationDate: boolean = false;

  constructor(public bookingService: BookingService,
    private eventService: EventService,
    public translate: TranslateService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getCurrentUserInfo().pipe(first()).subscribe(
      (user: User) => {
        if (user) {
          this.authorEmail = user.email;
        }
      });
    if (this.booking?.eventId) {
      const sub = this.eventService.getEventFromFirestore(this.booking.eventId, (event: Event) => {
        sub();
        this.beforeCancellationDate = new Date((this.booking?.startDate?.seconds - event.cancellationPeriod * 24 * 60 * 60) * 1000).getTime() > new Date().getTime();
      });  
    }
  }

  showAction(actionName: string): void {
    this.action = actionName;
    if (actionName == 'admin-send-sms') {
      this.validComponents = [false];
    }
    if (actionName == 'admin-cancel-booking') {
      this.validComponents = [true];
    }
    if (actionName == 'guest-cancel-booking') {
      this.validComponents = [true];
    }
    if (actionName == 'change-tickets') {
      this.validComponents = [true, true];
    }
    if (actionName == 'change-customer-name') {
      this.validComponents = [true, true];
    }
    if (actionName == 'change-customer-address') {
      this.validComponents = [true, true];
    }
    if (actionName == 'change-customer-phone') {
      this.validComponents = [true, true];
    }
    if (actionName == 'change-customer-comments') {
      this.validComponents = [true, true];
    }
    if (actionName == 'admin-change-status') {
      this.validComponents = [true, this.booking != null];
    }
    if (actionName == 'admin-change-venue') {
      this.validComponents = [true, this.booking != null];
    }
    if (actionName == 'admin-change-start-date') {
      this.validComponents = [true, this.booking != null];
    }
    if (actionName == null) {
      this.validComponents = [];
    }
  }

  canAction(actionName: string): boolean {
    if (actionName == 'change-tickets' || actionName == 'admin-cancel-booking') {
      return this.booking.status == 'active' || this.booking.status == 'waiting_list';
    }
    if (actionName == 'guest-cancel-booking') {
      return (this.booking.status == 'active' || this.booking.status == 'waiting_list') && this.beforeCancellationDate;
    }
    if (actionName == 'admin-change-status' || actionName == 'admin-change-venue' || actionName == 'change-customer-phone' || actionName == 'admin-change-start-date' || actionName == 'admin-send-sms') {
      return this.isAdmin;
    }
    return false;
  }

  executeAction(): void {
    if (this.validComponents.every((v) => v == true)) {
      let parameters = null;
      const notifySMS = this.action == 'admin-send-sms' || !this.isAdmin || this.sendSMS;
      const authorEmail = this.authorEmail || 'guest';
      const status = 'executing';

      if (this.action == 'admin-send-sms' || this.action == 'admin-cancel-booking' || this.action == 'guest-cancel-booking') {
        parameters = [this.smsContent];
      }
      if (this.action == 'change-tickets') {
        parameters = [this.smsContent, JSON.stringify(this.tickets) || null, this.bookingStatus || null];
      }
      if (this.action == 'change-customer-name') {
        parameters = [this.smsContent, this.customerName || null];
      }
      if (this.action == 'change-customer-address') {
        parameters = [this.smsContent, this.customerAddress || null];
      }
      if (this.action == 'change-customer-phone') {
        parameters = [this.smsContent, this.customerPhone || null];
      }
      if (this.action == 'change-customer-comments') {
        parameters = [this.smsContent, this.customerComments || null];
      }
      if (this.action == 'admin-change-status') {
        parameters = [this.smsContent, this.bookingStatus || null];
      }
      if (this.action == 'admin-change-venue') {
        parameters = [this.smsContent, this.bookingVenue || null];
      }
      if (this.action == 'admin-change-start-date') {
        parameters = [this.smsContent, this.bookingStartDate || null];
      }
      if (parameters) {
        if (this.booking) {
          this.bookingService.executeAction(this.booking,
            { name: this.action, status, parameters, notifySMS, authorEmail, timestamp: new Date() }
          );
          this.onExecutionDone.emit(true);
        }
        if (this.bookings?.length > 0) {
          for (let booking of this.bookings) {
            this.bookingService.executeAction(booking,
              { name: this.action, status, parameters, notifySMS, authorEmail, timestamp: new Date() }
            );
            this.onExecutionDone.emit(true);
          }
        }
        this.action = null;
      }
    }
  }

  //FORM LISTENERS
  onSendSmsChange(newSendSMS) {
    this.sendSMS = newSendSMS;
  }

  onSMSContentChange(newSMSContent) {
    this.smsContent = newSMSContent;
  }

  onTicketsChange({ newTicketsValue, newStatus }) {
    this.tickets = newTicketsValue;
    this.bookingStatus = newStatus;
  }

  onCustomerNameChange(newName) {
    this.customerName = newName;
  }

  onCustomerAddressChange(newAddress) {
    this.customerAddress = newAddress;
  }

  onCustomerPhoneChange(newPhone) {
    this.customerPhone = newPhone;
  }

  onCustomerCommentsChange(newComments) {
    this.customerComments = newComments;
  }

  onStatusChange(newStatus) {
    this.bookingStatus = newStatus;
  }

  onVenueChange(newVenue) {
    this.bookingVenue = newVenue;
  }

  onStartDateChange(newStartDate) {
    this.bookingStartDate = newStartDate;
  }
}
