import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CodeProtectionService } from '../services/code-protection.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-enter-code',
  templateUrl: './enter-code.component.html',
  styleUrls: ['./enter-code.component.scss']
})
export class EnterCodeComponent implements OnInit {
  codeControl = new FormControl('', Validators.required);
  error = false;
  attempts = 0;

  constructor(
    private codeProtectionService: CodeProtectionService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!environment.protectedByCode || this.codeProtectionService.isAccessGranted()) {
      this.router.navigate(['']);
    }
  }

  checkCode() {
    if (this.codeControl.valid) {
      const isValid = this.codeProtectionService.unlock(this.codeControl.value);
      if (isValid) {
        this.router.navigate(['']);
      } else {
        this.error = true;
        this.attempts++;
        this.codeControl.reset();
        // Remove shake class and re-add it to retrigger animation
        const field = document.querySelector('mat-form-field');
        field?.classList.remove('shake');
        setTimeout(() => field?.classList.add('shake'), 10);
      }
    }
  }
}
