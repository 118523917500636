import { Component, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { MyTicketsComponent } from '../my-tickets/my-tickets.component';

@Component({
  selector: 'app-my-tickets-page',
  templateUrl: './my-tickets-page.component.html',
  styleUrls: ['./my-tickets-page.component.scss']
})
export class MyTicketsPageComponent {
  environment = environment;
  @ViewChild(MyTicketsComponent) myTicketsComponent: MyTicketsComponent;

  onLogout() {
    if (this.myTicketsComponent) {
      this.myTicketsComponent.logout();
    }
  }
}
