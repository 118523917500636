<div class="wrapper" > 
  <div *ngIf="environment.ui.showOnForm.rsvp; else notAvailable" class="mat-body my-tickets mat-elevation-z1 page">
    <div class="header-container">
      <h1 class="mat-h1" translate>My Tickets</h1>
      <button *ngIf="myTicketsComponent?.bookings" mat-stroked-button (click)="onLogout()">{{'Log out' | translate}}</button>
    </div>
    <app-my-tickets></app-my-tickets>
  </div>
  <ng-template #notAvailable>
    <div>{{'Booking not available in this license' | translate}}</div>
  </ng-template>
</div>
